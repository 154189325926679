<template>
  <div class="tests">
    <Navbar/>  
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-8 offset-lg-2 h1-wrapper">
                <h1>Рассчитать готовность</h1>
            </div>
        </div>

        <div class="row">
          
          <div class="col-12 col-lg-8 offset-lg-2">
            <div v-if="this.finished || this.$store.state.hasSubmitForm" class="row">
              <div class="col-12 feed">
                Спасибо за Ваши ответы. В ближайшее время с Вами свяжутся.
              </div>
            </div>
            <div v-if="!this.finished && !this.$store.state.hasSubmitForm" class="row">
                <div class="col-12 col-lg-6">
                        <div class="questions">
                            <label for="age" class="form-label">Укажите Ваш возраст</label>
                            <input class="form-control" ref="age" v-model="this.lead.age" id="age" type="number">
                        </div>

                        <div class="questions">
                            <label for="nationality" class="form-label">Гражданство</label>
                            <input ref="nationality" class="form-control" v-model="this.lead.nationality" id="nationality" type="text">
                        </div>

                        <div class="questions">
                            <label for="education" class="form-label">Уровень текущего образования</label>
                            <select ref="education" v-model="this.lead.education" class="form-select" id="education">
                              <option value=""></option>
                              <option value="среднее">среднее</option>
                              <option value="средне-специальное">средне-специальное</option>
                              <option value="бакалавриат">бакалавриат - 3 года</option>
                              <option value="высшее бакалавриат">высшее бакалавриат - 4 года</option>
                              <option value="высшее специалитет">высшее специалитет - 5 лет</option>
                              <option value="высшее магистратура">высшее магистратура - 6 лет</option>
                            </select>
                        </div>

                        <div class="questions">
                            <label for="english" class="form-label">Укажите Ваш уровень английского языка</label>
                            <select ref="english" class="form-select" v-model="this.lead.english" id="english">
                              <option value=""></option>
                              <option value="начальный">начальный (А1-А2)</option>
                              <option value="средний">средний (B1-B2)</option>
                              <option value="продвинутый">продвинутый (C1-C2)</option>
                            </select>
                        </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="questions">
                        <label for="french" class="form-label">Укажите Ваш уровень французского языка</label>
                        <select ref="french" class="form-select" v-model="this.lead.french" id="french">
                          <option value=""></option>
                          <option value="начальный">начальный (А1-А2)</option>
                          <option value="средний">средний (B1-B2)</option>
                          <option value="продвинутый">продвинутый (C1-C2)</option>
                        </select>
                    </div>

                    <div class="questions">
                        <label for="budget" id="label-budget" class="form-label">Укажите Ваш бюджет на обучение (евро)</label>
                        <input @change="validation" ref="budget" v-model="this.lead.budget" class="form-control" id="budget" min="0" type="number">
                    </div>

                    <div class="questions">
                        <label for="name" class="form-label">Ваше имя</label>
                        <input ref="name" class="form-control" v-model="this.lead.name" id="name" type="text">
                    </div>
                    <div class="questions">
                        <label for="phone" class="form-label">Оставьте свой номер WhatsApp/Телеграм</label>
                        <input ref="phone" v-model="this.lead.phone" class="form-control" id="phone" type="text">
                    </div>
                </div>
                <div class="col-12">
                        <div class="questions text-center">
                            <button class="btn btn-primary" v-if="readyToProcess()" type="button" @click="processUpdatePerson()">Готово</button>
                        </div>
                </div>
                <div id="captcha">
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
import {mapMutations, mapState, Store} from 'vuex'

export default {
  name: 'Home',
  data(){
      return {
          lead:{
            age: "",
            nationality:"",
            education:"",
            english:"",
            french:"",
            budget:"",
            name:"",
            phone:""
          },
          finished:""
      }
  },
  computed:{
    ...mapState({
      person: 'person'
    }),
  },
  methods:{
    ...mapMutations(['updatePerson']),
    processUpdatePerson() {
        this.updatePerson(this.lead)
        this.finished = true
    },
    readyToProcess(){
      let isReady = true
      for (let prop in this.lead) {
          if(!this.lead[prop]){
            isReady=false;
          }
      }
      return isReady;
    },
    validation(input){
      const element = input.target;
      if(element.id === "budget"){
        let label = document.querySelector('#label-budget')
        if(element.value < 3000){
          label.textContent = "Внимание, маленький бюджет"
          label.style.color = "red"
        }
        else {
          label.textContent = "Укажите Ваш бюджет на обучение (евро)"
          label.style.color = "inherit"
        }
      }
    }
  },
  components: {
    Navbar,
  }
}
</script>
